export const environment = {
  production: true,
  name: 'prod',
  useEmulators: false,
  firebase: {
    config: {
      apiKey: 'AIzaSyDnF7DxEiEA32VeQJwwMwHVKF7w59W4BmA',
      authDomain: 'store-block-web-test.firebaseapp.com',
      projectId: 'store-block-test',
      storageBucket: 'store-block-test.appspot.com',
      messagingSenderId: '725961300756',
      appId: '1:725961300756:web:54962e3d6a7af515b50001',
      measurementId: 'G-9GGM9C54RV'
    },
    verificationRedirectUrl: 'https://www.store-block-test.xyz/'
  },
  region: 'asia-southeast1',
  recaptchaKey: '6LdLqPAnAAAAAHjyBDVhMv9W1x0lBwp794xiTmWk',
  swiftPayRedirectUrl: 'https://api.pay.sandbox.live.swiftpay.ph',
  buildNumber: '20241209.3',
  appUrl: 'https://merchant.store-block-test.xyz/',
  apiUrl: 'https://asia-southeast1-store-block-test.cloudfunctions.net/api'
};
